<template>
  <PageWrapper>
    <div id="AccountPage">
      <div class="incomplete_remind">
        <IncompleteRemind />
      </div>
      <div class="create_Btn" v-if="openAdditionAccountEnabled">
        <el-button icon="el-icon-plus" size="mini" type="primary" data-testid="openLiveAccount" @click="addAccount">{{
          $t('common.button.addAccount')
        }}</el-button>
      </div>
      <Empty class="empty_box" v-if="accountTableData.length === 0 && !isLoading"
        :description="$t('common.field.noAccountData')"></Empty>
      <el-row class="account_cards" :gutter="16" v-else>
        <el-col :xl="8" :md="12" :xs="24" class="account_item" v-for="item in accountTableData" :key="item.index">
          <div :class="['item_box', { inactive_item_box: item.status === 'Inactive' || item.status === 'Rejected' }]">
            <div class="item_top">
              <div class="top_left">
                <span class="server_category" v-if="item.serverCategory">
                  {{ `MT${item.serverCategory}` }}
                </span>
                <span class="account_num">
                  {{ item.mt4_account }}
                </span>
                <el-divider direction="vertical"></el-divider>
                <span :class="handleStatusColor(item.status)" v-if="!showDiffTable">
                  {{ item.status | accStatus }}
                </span>
                <span class="pending_status pending_color" v-else>
                  <span>Pending</span>
                  <el-popover placement="bottom" width="240" trigger="hover"
                    :content="$t('home.pendingTooltip', { number: item.serverCategory })">
                    <i slot="reference" class="el-icon-warning-outline"></i>
                  </el-popover>
                </span>
              </div>
              <div class="setting_box" v-if="item.status === 'Active'">
                <el-popover placement="bottom-end" trigger="click" :offset="20" popper-class="setting_popover">
                  <ul class="popover_btn_list">
                    <li class="btn_item" v-if="!showDiffTable && !showDiffButton">
                      <el-button @click="onChangePassword(item)" data-testid="resetMT4PW">{{
                        $t('resetPassword.header')
                      }}</el-button>
                    </li>
                    <li class="btn_item" v-if="!showDiffTable && !showDiffButton">
                      <el-button @click="onForgotPassword(item)" data-testid="forgotPw">{{
                        $t('common.button.forgetPw')
                      }}</el-button>
                    </li>
                    <li class="btn_item" v-if="!item.isInBlackList">
                      <el-button :disabled="item.leverageStatus !== 1" @click.stop.prevent="onChangeLeverage(item)">{{
                        $t('common.button.changeLeverage')
                      }}</el-button>
                      <i v-if="item.leverageStatus !== 1" class="prompt_icon el-icon-warning-outline"
                        @click.stop.prevent="showleverageAlreadyInProcessPopUp()"></i>
                      <el-popover v-if="item.tag" class="icon" placement="bottom" width="260" trigger="hover">
                        <i class="el-icon-question prompt_icon" slot="reference"></i>
                        <div class="popover-context">{{ $t('home.leverage.autoRestore') }}</div>
                      </el-popover>
                    </li>
                  </ul>
                  <span slot="reference" class="setting_icon el-icon-setting"></span>
                </el-popover>
              </div>
            </div>
            <div class="item_center">
              <CurrencyIcon :currency="item.currency ? item.currency : '--'" />
              <span class="equity_box">{{ item.equity | formatNumber }}</span>
              <span class="currency_box" v-if="item.currency">{{ item.currency }}</span>
            </div>
            <div class="item_bottom">
              <div class="bottom_left">
                <span>{{ item.status === 'Active' ? `${item.leverage} : 1` : '--' }}</span>
                <span v-if="item.server">
                  <el-divider direction="vertical"></el-divider>
                  <span>{{ item.server }}</span>
                </span>
                <span>
                  <el-divider direction="vertical"></el-divider>
                  <span>{{ $config.accountTypeMaps[item.mt4_account_type] }}</span>
                </span>
              </div>
              <div class="bottom_right">
                <el-button data-testid="depositFunds" @click="getDepositBlacklist()">{{
                  $t('common.button.depositFunds')
                }}</el-button>
                <el-button data-testid="withdrawFunds" @click="getWithdrawalBlacklist()">{{
                  $t('common.button.withdrawFunds')
                }}</el-button>
              </div>
            </div>
          </div>
        </el-col>
      </el-row>

      <!-- CHANGE LEVERAGE 弹框开始 -->
      <ChangeLeverage ref="changeLeverageRef" method="live" @resetTable="queryMetaTraderAccountDetails" />
      <!-- CHANGE LEVERAGE 弹框结束 -->

      <!-- CHANGE LEVERAGE already 弹框开始 -->
      <SDialog :visible.sync="leverageAlreadyVisible" :showFooter="false">
        <StatusMessage type="success">
          <template #content>
            <p class="home_status_content">
              {{ $t('home.leverage.alreadyApplied', { platform: $config.info.fullName }) }}
            </p>
          </template>
        </StatusMessage>
      </SDialog>
      <!-- CHANGE LEVERAGE already 弹框结束 -->

      <!-- 点击 CHANGE PASSWORD  表单弹框开始 -->
      <ChangePassword :changePasswordVisible.sync="changePasswordVisible" :number="number" :isDemo="isDemo">
      </ChangePassword>
      <!-- 点击 CHANGE PASSWORD  表单弹框结束 -->

      <!-- FORGOT PASSWORD 开始 -->
      <ForgotPassword ref="forgotPassword" :isDemo="isDemo" />
      <!-- FORGOT PASSWORD 结束 -->

      <!-- active 弹框开始 -->
      <Active :uuid="uuid" :reActivateMetaTraderLogin="reActivateMetaTraderLogin" :activateVisible.sync="activateVisible"
        :activateConfirmVisible.sync="activateConfirmVisible" @resetTable="queryMetaTraderAccountDetails"></Active>
      <!-- active 弹框结束 -->

      <!-- active Confirm 弹框开始 -->
      <SDialog :visible.sync="activateConfirmVisible" :showFooter="false">
        <StatusMessage type="success">
          <template #content>
            <p class="home_status_content" v-html="$t('home.activate.confirm')"></p>
          </template>
        </StatusMessage>
      </SDialog>
      <!-- active Confirm 弹框结束 -->

      <SDialog :visible.sync="ndbDialog" :showFooter="false">
        <div class="ndb_dialog_body">
          <p>{{ $t('promotion.notification.ndb.inst1') }}</p>
          <p v-html="$t('promotion.notification.ndb.inst2', { date: ndbDialogInfo.date })"></p>
          <p>{{ $t('promotion.notification.ndb.inst3') }}</p>
          <p v-html="$t('promotion.notification.ndb.inst4', { mail: ndbDialogInfo.mail, phone: ndbDialogInfo.phone })">
          </p>
        </div>
      </SDialog>

      <SDialog :visible.sync="dbDialog" :showFooter="false">
        <div class="db_dialog_body">
          <p>{{ $t('promotion.notification.db.inst1') }}</p>
          <p v-html="$t('promotion.notification.db.inst2', { date: dbDialogInfo.date })"></p>
          <p>{{ $t('promotion.notification.db.inst3') }}</p>
          <p v-html="$t('promotion.notification.db.inst4', { mail: dbDialogInfo.mail, phone: dbDialogInfo.phone })"></p>
        </div>
      </SDialog>
      <AddLiveAccount ref="liveAccountRef" />
    </div>
  </PageWrapper>
</template>

<script>
import {
  apiQueryMetaTraderAccountDetails,
  apiQueryLeverageStatuses,
  apiParticipatedCampaigns,
  apiJoinedDepositBonusCampaign,
  apiCampaignInfo,
} from '@/resource'
import { apiID3Status } from '@/resource/register'
import Active from '@/components/home/Active'
import moment from 'moment'
import { mapState } from 'vuex'
import CurrencyIcon from '@/views/home/components/CurrencyIcon.vue'
import ChangePassword from '@/views/home/components/ChangePassword.vue'
import ForgotPassword from '@/views/home/components/ForgotPassword.vue'
import ChangeLeverage from '@/views/home/components/ChangeLeverage.vue'
import IncompleteRemind from '@/views/home/components/IncompleteRemind.vue'
import AddLiveAccount from '@/views/home/components/AddLiveAccount.vue'
import openAccountMixin from '@/mixins/openAccount'
import blackList from '@/mixins/blackList'

export default {
  name: 'Home',
  components: {
    Active,
    ForgotPassword,
    ChangeLeverage,
    ChangePassword,
    CurrencyIcon,
    IncompleteRemind,
    AddLiveAccount,
  },
  data() {
    return {
      uuid: this.$store.state.common.uuid,
      number: null,
      allMetaTraderAccounts: [],
      accountTableData: [],
      leverageStatus: {},
      leverageAlreadyVisible: false,
      changePasswordVisible: false,
      activateVisible: false,
      activateConfirmVisible: false,
      reActivateMetaTraderLogin: null,
      isDemo: false,
      ndbDialog: false,
      ndbDialogInfo: {
        date: '',
        mail: '',
        phone: '',
      },
      dbDialog: false,
      dbDialogInfo: {
        date: '',
        mail: '',
        phone: '',
      },
    }
  },
  mixins: [openAccountMixin, blackList],
  mounted() {
    if (!this.idPass) this.checkIdPass()
    this.queryMetaTraderAccountDetails()
  },
  computed: {
    isLoading() {
      return this.$store.state.common.isLoading
    },
    idPass() {
      return this.$store.state.common.idPass
    },
    showDiffTable() {
      return this.allMetaTraderAccounts.length == 1 && !this.idPass
    },
    showDiffButton() {
      return this.allMetaTraderAccounts.length == 1 && !this.idPass && this.allMetaTraderAccounts[0].mt4_account != '--'
    },
    ...mapState('promotion', {
      eligibleCampaigns: 'eligibleCampaigns',
    }),
  },
  watch: {
    eligibleCampaigns(newValue) {
      if (Array.isArray(newValue) && newValue.length > 0) {
        this.getNdbInfo()
      }
    },
  },
  beforeRouteEnter(_, from, next) {
    next(route => {
      route.prevRoute = from.name
    })
  },
  methods: {
    checkIdPass() {
      apiID3Status().then(resp => {
        if (resp.data.data) this.$store.commit('common/setIdPass', true)
      })
    },
    addAccount() {
      this.$refs.liveAccountRef.visible = true
    },
    async getNdbInfo() {
      if (this.prevRoute === 'login') {
        const noDepositBonusId = 2
        const depositBonusId = 6
        let noDepositBonus
        let depositBonus

        await apiParticipatedCampaigns().then(resp => {
          if (resp.data.code === 0) {
            noDepositBonus = resp.data.data.includes(noDepositBonusId)
          }
        })

        await apiJoinedDepositBonusCampaign().then(resp => {
          if (resp.data.code === 0 && resp.data.data) {
            depositBonus = resp.data.data.some(f => f.campaignId === depositBonusId)
          }
        })

        if (noDepositBonus) {
          await apiCampaignInfo(noDepositBonusId)
            .then(resp => {
              if (resp.data.code === 0 && resp.data.data.expiring) {
                this.ndbDialog = true
                this.constructDialog(this.ndbDialogInfo, resp, this.ndbDialog)
              }
            })
            .catch(e => {
              console.log(e)
            })
        }

        if (depositBonus) {
          await apiCampaignInfo(depositBonusId)
            .then(resp => {
              if (resp.data.code === 0 && resp.data.data.expiring) {
                this.dbDialog = true
                this.constructDialog(this.dbDialogInfo, resp, this.dbDialog)
              }
            })
            .catch(e => {
              console.log(e)
            })
        }
      }
    },
    constructDialog(dialogObj, resp) {
      dialogObj.mail = this.GLOBAL_CONTACT_EMAIL
      dialogObj.phone = this.$config.info.getPhone(this.$store.state.common.regulator)
      const timeStamp = new Date(resp.data.data.expiredDate)
      dialogObj.date = moment.tz(timeStamp, 'Asia/Aden').format('DD/MM/YYYY')
    },
    queryMetaTraderAccountDetails(hiddenMtAccountIdList = [], unHide = false) {
      apiQueryMetaTraderAccountDetails({
        hiddenMtAccountIdList: hiddenMtAccountIdList,
        unHide: unHide,
      }).then(resp => {
        this.allMetaTraderAccounts = resp.data
        this.formatAccounts()
        this.queryLeverageStatus()
      })
    },
    formatAccounts() {
      this.allMetaTraderAccounts.forEach(element => {
        if (element.status != 1) {
          this.formatPendingAccount(element)
        }
        if (element.isArchive == 1 || element.isArchive == 2) {
          this.formatArchieveAccount(element)
        }
        this.setStatus(element)
      })
    },
    setStatus(account) {
      if (account.isArchive && (account.isArchive == 1 || account.isArchive == 2)) {
        account.status = 'Inactive'
        return
      }
      if (account.status == 1) {
        account.status = 'Active'
        return
      }
      if (account.status == 0) {
        account.status = 'Processing'
        return
      }
      if (account.status == 2) {
        account.status = 'Rejected'
        return
      }
    },
    queryLeverageStatus() {
      apiQueryLeverageStatuses({
        user_id: this.uuid,
      }).then(resp => {
        this.leverageStatus = resp.data
        this.updateLeverageStatus()
        this.accountTableData = this.allMetaTraderAccounts
      })
    },
    updateLeverageStatus() {
      this.allMetaTraderAccounts.forEach(element => {
        if (element.mt4_account == '--' || element.mt4_account == null) {
          return
        }
        if (this.leverageStatus[element.mt4_account.toString()]) {
          if (this.leverageStatus[element.mt4_account.toString()].status == 0) {
            element.leverageStatus = 0
            return
          }
        }
        element.leverageStatus = 1
      })
    },
    formatPendingAccount(account) {
      account.equity = '--'
      account.mt4_account = '--'
    },
    formatArchieveAccount(account) {
      account.equity = '--'
      account.currency = '--'
    },
    showleverageAlreadyInProcessPopUp() {
      this.leverageAlreadyVisible = true
    },
    handleStatusColor(status) {
      switch (status) {
        case 'Active':
          return 'active_color'
        case 'Inactive':
          return 'inactive_color'
        case 'Processing':
          return 'pending_color'
        case 'Rejected':
          return 'rejected_color'
        default:
          break
      }
    },
    onChangePassword(row) {
      this.number = row.mt4_account
      this.changePasswordVisible = true
    },
    onForgotPassword(row) {
      this.$refs.forgotPassword.forgotPwdClick(row.mt4_account)
    },
    onChangeLeverage(row) {
      this.$refs.changeLeverageRef.leverageClick(row)
    },
    openReactivateConfirmationPopUp(metaTraderLogin) {
      this.reActivateMetaTraderLogin = metaTraderLogin
      this.activateVisible = true
    },
  },
}
</script>

<style lang="scss">
@import '@/views/home/css/home.scss';
</style>
