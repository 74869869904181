<template>
  <div id="LiveAccount">
    <SDrawer :title="$t('common.field.addLiveAccount')" :visible.sync="visible" custom-class="common_account_drawer"
      :showFooter="!success">
      <el-form :model="formData" :rules="formRules" ref="formData" label-position="top" v-if="!success">
        <el-form-item :label="$t('common.field.platform')" prop="tradingPlatform" class="platform_form_item">
          <el-radio-group v-model="formData.tradingPlatform" @input="changePlatform">
            <el-radio v-for="(item, index) in $config.openLiveAccount.choosePlatform(regulator)" :data-testid="item.value"
              :label="item.value" :key="item.value">
              <img src="@/assets/images-1/mt4.png" v-if="item.value === 'mt4'" class="platform_img" />
              <img src="@/assets/images-1/mt5.png" v-if="item.value === 'mt5'" class="platform_img" />
            </el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item :label="$t('common.field.accountType')" prop="accountType">
          <el-select v-model="formData.accountType" :placeholder="$t('common.field.select')" @change="changeAccountType">
            <el-option v-for="item in accountTypeOptions" :key="item.value" :label="item.label" :value="item.value"
              :data-testid="item.value">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item :label="$t('common.field.currency')" prop="currency">
          <el-select v-model="formData.currency" :placeholder="$t('common.field.select')">
            <el-option v-for="item in $config.openLiveAccount.chooseCurrency(regulator, formData.accountType)"
              :key="item.value" :label="item.value" :value="item.currency" :data-testid="item.currency">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item :label="$t('openAdditAcc.notes')">
          <el-input type="textarea" :rows="4" :placeholder="$t('common.field.pleaseInput')"
            v-model="formData.notes"></el-input>
        </el-form-item>
        <el-form-item>
          <el-checkbox name="type" v-model="checkStatus" data-testid="tncConfirm" class="tnc_checkbox">
            <div class="tnc_label">
              <i18n path="openAdditAcc.byTicking" tag="div" class="tnc">
                <template v-slot:tnc>
                  <p @click="showTNC = !showTNC" data-testid="showTNC">{{ $t('openAdditAcc.tnc') }}</p>
                </template>
              </i18n>
            </div>
          </el-checkbox>
          <!-- <div class="tnc_text_box" v-show="showTNC" v-html="
            $t($config.openLiveAccount.getAgreeCondition(regulator), {
              platform: $config.info.fullName,
              regulatorUrl: GLOBAL_DOMAIN_WEBSITE,
            })
          "></div> -->
          <ul class="tnc_text_box" v-show="showTNC" v-for="(item, index) in getProtocol" :key="index">
            <li v-html="$t(`${item}`, {
              capitalName: $config.info.capitalName,
              protocolUrl: getProtocolUrl
            })"></li>
          </ul>
        </el-form-item>
      </el-form>
      <template #footer>
        <el-button type="primary" @click="submitClick" data-testid="submit">{{ $t('common.button.submit') }}</el-button>
      </template>
    </SDrawer>
    <SDialog :visible.sync="success">
      <StatusMessage :title="$t('promotion.successful')">
        <template #content>
          <p class="home_status_content" v-html="$t('openAdditAcc.succ')"></p>
        </template>
      </StatusMessage>
      <template #footer>
        <el-button @click="goHome">{{ $t('common.button.bkToHm') }}</el-button>
      </template>
    </SDialog>
    <SDialog :visible.sync="pammVisible">
      <div class="confirm_pamm_dialog_body">
        <p><span class="required_icon">*</span>{{ $t('openAdditAcc.pamm') }}</p>
        <p class="confirm_tip">*{{ $t('openAdditAcc.pammConfirm') }}</p>
      </div>
      <template #footer>
        <el-button @click="resetType()">{{ $t('common.button.cancel') }}</el-button>
        <el-button type="primary" @click="isShowDialog = false">
          {{ $t('common.button.confirm') }}
        </el-button>
      </template>
    </SDialog>
  </div>
</template>

<script>
import { apiApplyAdditionalAccount } from '@/resource'
export default {
  name: 'LiveAccount',
  data() {
    return {
      visible: false,
      formData: {
        tradingPlatform: null,
        accountType: null,
        currency: null,
        notes: null,
      },
      formRules: {
        tradingPlatform: [{ required: true, message: this.$t('openAdditAcc.platErr'), trigger: 'blur' }],
        accountType: [{ required: true, message: this.$t('openAdditAcc.accTypeErr'), trigger: 'blur' }],
        currency: [{ required: true, message: this.$t('openAdditAcc.currError'), trigger: 'blur' }],
      },
      checkStatus: false,
      success: false,
      showTNC: false,
      isShowDialog: true,
      resultVisible: true,
    }
  },
  computed: {
    countryCode() {
      return this.$store.state.common.countryCode
    },
    pammVisible() {
      return this.isShowDialog && this.accountType === 'PAMM'
    },
    accountTypeOptions() {
      return this.formData.tradingPlatform === 'mt4' ? this.showMT4LiveType : this.showMT5LiveType
    },
    showMT4LiveType() {
      return this.$config.openLiveAccount.chooseLiveType(this.regulator).filter(f => {
        if (f.restrictCountries) return !f.restrictCountries.includes(parseInt(this.countryCode))
        if (f.onlyCountries) return f.onlyCountries.includes(parseInt(this.countryCode))
        return f
      })
    },
    showMT5LiveType() {
      return this.$config.openLiveAccount.mt5ChooseType(this.regulator).filter(f => {
        if (f.restrictCountries) return !f.restrictCountries.includes(parseInt(this.countryCode))
        if (f.onlyCountries) return f.onlyCountries.includes(parseInt(this.countryCode))
        return f
      })
    },
    getProtocolUrl() {
      return this.$config.getProtocolUrl(this.regulator)
    },
    getProtocol() {
      return this.$config.getProtocol(this.regulator)
    },
  },
  methods: {
    goHome() {
      this.$router.push('home')
    },
    changePlatform(val) {
      this.resetType()
      this.resetCurrency()
    },
    changeAccountType() {
      this.isShowDialog = true
      this.resetCurrency()
    },
    resetType() {
      this.formData.accountType = null

      this.isShowDialog = true
    },
    resetCurrency() {
      this.formData.currency = null
    },
    submitClick() {
      this.$refs.formData.validate(valid => {
        if (valid) {
          if (this.checkStatus) {
            apiApplyAdditionalAccount(this.formData).then(resp => {
              if (resp.data.code === 0) {
                this.success = true
              }
            })
          } else {
            this.$message({
              message: this.$t('common.formValidation.tnc'),
              type: 'warning',
            })
          }
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/views/home/css/addAccount.scss';
</style>
